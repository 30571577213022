import React, { useState } from "react";

import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import { DataGrid, GridOverlay, GridToolbarContainer, GridToolbar } from "@material-ui/data-grid";
import clsx from "clsx";
import { Link } from "gatsby";
import moment from "moment";

import { Coupon, CouponDataGridParams } from "../../types/coupons";

import { Country } from "~/src/constants/countries";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbar />
    </GridToolbarContainer>
  );
}

const CouponDataGrid = ({ data, loading, country, setCountry, toggleCoupon, deleteCoupon }: CouponDataGridParams) => {
  const [toggling, setToggling] = useState<boolean>(false);

  const renderCustomLoadingOverlay = () => {
    return (
      <GridOverlay>
        <div className="absolute top-0 w-full">
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  };

  const columns = [
    {
      headerName: "id",
      type: "string",
      sorteable: false,
    },
    {
      field: "created_at",
      headerName: "Fecha Creación",
      sortable: true,
      type: "date",
      width: 160,
      renderHeader: () => <strong>Fecha Creación</strong>,
      valueFormatter: (params: { row: Coupon }) => {
        return moment(params.row.created_at).format(`DD/MM/YYYY`);
      },
    },
    {
      field: "begin_date",
      headerName: "Fecha Inicio",
      sortable: true,
      type: "date",
      width: 140,
      renderHeader: () => <strong>Fecha Inicio</strong>,
      valueFormatter: (params: { row: Coupon }) => {
        return params.row.begin_date ? moment(params.row.begin_date).format(`DD/MM/YYYY`) : "S/F";
      },
    },
    {
      field: "expiration_date",
      headerName: "Fecha Expiración",
      sortable: true,
      type: "date",
      width: 170,
      renderHeader: () => <strong>Fecha Expiración</strong>,
      valueFormatter: (params: { row: Coupon }) => {
        return params.row.expiration_date ? moment(params.row.expiration_date).format(`DD/MM/YYYY`) : "S/F";
      },
    },
    {
      field: "referral_code",
      headerName: "Código",
      sortable: true,
      type: "string",
      width: 140,
      renderHeader: () => <strong>Código</strong>,
    },
    {
      field: "discount",
      headerName: "Descuento",
      sortable: false,
      type: "string",
      width: 120,
      renderHeader: () => <strong>Descuento</strong>,
      valueFormatter: (params: { value: number }) => {
        return `${params.value * 100}%`;
      },
    },
    {
      field: "referrals",
      headerName: "Usos",
      sortable: true,
      type: "number",
      width: 80,
      renderHeader: () => <strong>Usos</strong>,
    },
    {
      field: "conversions",
      headerName: "Conversiones",
      sortable: true,
      type: "number",
      width: 160,
      renderHeader: () => <strong>Conversiones</strong>,
    },
    {
      field: "remaining_uses",
      headerName: "Conversiones restantes",
      sortable: true,
      type: "number",
      width: 210,
      renderHeader: () => <strong>Conversiones restantes</strong>,
      valueFormatter: (params: { row: Coupon }) => {
        return params.row.remaining_uses === null ? "Ilimitados" : params.row.remaining_uses;
      },
    },
    {
      field: "user_uses",
      headerName: "Max usos X usuario",
      sortable: true,
      type: "number",
      width: 200,
      renderHeader: () => <strong>Max usos por usuario</strong>,
      valueFormatter: (params: { value: number }) => {
        return params.value === null ? "Ilimitados" : params.value;
      },
    },
    {
      field: "_",
      headerName: "Acciones",
      width: 180,
      sortable: false,
      renderHeader: () => <strong style={{ margin: "auto" }}>Acciones</strong>,
      renderCell: (params: { row: Coupon }) => (
        <div style={{ margin: "auto" }}>
          <Link replace to={`/coupons/${params.row.id}/`} style={{ marginRight: "30px" }}>
            Editar
          </Link>
          <button
            onClick={async () => {
              await deleteCoupon(params.row.id);
            }}
          >
            Eliminar
          </button>

          {toggling ? (
            <LinearProgress />
          ) : (
            <Switch
              checked={params.row.active}
              onChange={async () => {
                setToggling(true);
                await toggleCoupon(params.row.id, params.row.active);
                setToggling(false);
              }}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={clsx("w-1/2", "ml-2 px-2 py-4", "flex flex-col")}>
        <p className={clsx("mb-2", "text-lg")}>Elige un país...</p>
        <Select value={country} onChange={(e) => setCountry(e.target.value as Country)} fullWidth>
          {Object.entries(Country).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
        <p className={clsx("mt-3", "text-sm text-gray-600")}>Actualmente viendo los cupones de {country}</p>
      </div>
      <DataGrid
        components={{
          LoadingOverlay: renderCustomLoadingOverlay,
          Toolbar: CustomToolbar,
        }}
        loading={loading}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columns={columns}
        rows={data}
        pageSize={10}
        disableSelectionOnClick
      />
    </>
  );
};

export default CouponDataGrid;
