import axios from "axios";

import { Country } from "../constants/countries";
import useAuthenticationMiducStore from "../store/authenticationMiduc";
import { Coupon } from "../types/coupons";
import { LabServiceResponse } from "../types/lab-services";

import apiUrl from "~/src/utils/api-config";
import namespaced from "~/src/utils/debug";

const debug = namespaced("api");

const client = axios.create({ baseURL: apiUrl });

client.interceptors.request.use(
  (config) => {
    const { token } = useAuthenticationMiducStore.getState();
    if (token) {
      config.headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const fetchCoupons = async (country: Country): Promise<Coupon[] | undefined> => {
  try {
    const res = await client.get<Coupon[]>(`/referrals?country=${country}`);
    debug("[fetchCoupons] response was", res.status);
    if (res.status !== 200) return undefined;
    return res.data;
  } catch (err) {
    debug(err);
  }
};

export const createCoupon = async (coupon: Coupon): Promise<Coupon | undefined> => {
  try {
    const res = await client.post("/referrals/", coupon);
    debug("[createCoupon] response was", res.status);
    if (res.status !== 200) return undefined;
    return res.data;
  } catch (err) {
    debug(err);
  }
};

export const getCoupon = async (id: string): Promise<Coupon | undefined> => {
  try {
    const res = await client.get<Coupon>(`/referrals/${id}/`);
    debug("[getCoupon] response was", res.status);
    if (res.status !== 200) return undefined;
    return res.data;
  } catch (err) {
    debug(err);
  }
};

export const updateCoupon = async (data: Coupon): Promise<Coupon | undefined> => {
  try {
    const res = await client.patch<Coupon>(`/referrals/${data.id}/`, data);
    debug("[updateCoupon] response was", res.status);
    if (res.status !== 200) return undefined;
    return res.data;
  } catch (err) {
    debug(err);
  }
};

export const deleteCoupon = async (id: number): Promise<any> => {
  try {
    const res = await client.delete(`/referrals/${id}/`);
    debug("[deleteCoupon] response was", res.status);
    if (res.status !== 204) return undefined;
    return res.status;
  } catch (err) {
    debug(err);
  }
};

export const toggleCoupon = async (id: number, active: boolean): Promise<Coupon | undefined> => {
  try {
    const res = await client.patch<Coupon>(`/referrals/${id}/`, {
      active: !active,
    });
    debug("toggleCoupon response was", res.status);
    if (res.status !== 200) return undefined;
    return res.data;
  } catch (err) {
    debug(err);
  }
};

export const getCouponLabServices = async (id: number): Promise<any> => {
  try {
    const res = await client.get<LabServiceResponse>(`/referrals/${id}/lab-services/`);
    debug("getCouponLabServices response was", res.status);
    if (res.status !== 200) return undefined;
    return res.data.data;
  } catch (err) {
    debug(err);
  }
};
