import React, { useState, useCallback, useEffect } from "react";

import axios from "axios";
import { Link } from "gatsby";
import SearchBar from "material-ui-search-bar";
import styled from "styled-components";
import Swal from "sweetalert2";

import CouponDataGrid from "../../components/coupons/CouponDataGrid";
import SEO from "../../components/SEO";
import { deleteCoupon, fetchCoupons, toggleCoupon } from "../../utils/api-coupons";

import PrivateRoute from "~/src/components/Authentication/MiducPrivateRoute";
import TokenValidator from "~/src/components/Authentication/MiducTokenValidator";
import Layout from "~/src/components/Layout";
import { Country } from "~/src/constants/countries";
import { Coupon } from "~/src/types/coupons";
import apiUrl from "~/src/utils/api-config";

const MiniSearchBar = styled(SearchBar)`
  width: 350px;
  margin-bottom: 10px;
`;

const Coupons = (): JSX.Element => {
  const [coupons, setCoupons] = useState<Array<Coupon>>([]);
  const [country, setCountry] = useState<Country>(Country.CHILE);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = useCallback(async () => {
    setCoupons([]);
    setLoading(true);
    try {
      const result = await fetchCoupons(country);
      setCoupons(result || []);
      setLoading(false);
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  }, [country]);

  const fetchDataWithFilters = async (search: string) => {
    setCoupons([]);
    setLoading(true);
    const url = `${apiUrl}/referrals/referrals_list/`;

    try {
      // This way we can extend the filters later, for now we only search by name
      // Also it isn't worth it to make a Filter context for now
      const request = await axios.request({
        method: "get",
        url: url,
        params: {
          name: search,
        },
      });
      setCoupons(request.data || []);
      setLoading(false);
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Todo: hay que refactor esto porque cada vez que se actualiza 1 puro cupon,
  // se hacen fetch todos de nuevo
  const handleToggleCoupon = async (id: number, active: boolean) => {
    const result = await toggleCoupon(id, active);
    if (result) {
      await fetchData();
    } else {
      Swal.fire({
        title: "Error",
        text: "Coupon couldn't be toggled",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleDeleteCoupon = async (id: number) => {
    const result = await deleteCoupon(id);
    if (result === 204) {
      await fetchData();
    } else {
      Swal.fire({
        title: "Error",
        text: "Coupon couldn't be deleted",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <Layout>
      {/* @ts-expect-error TokenValidator cannot be used as a JSX element */}
      <TokenValidator>
        {/* @ts-expect-error PrivateRoute cannot be used as a JSX element */}
        <PrivateRoute>
          <>
            <SEO title="Examedi Marketing Cupones" />
            <div className="min-h-screen grid w-full bg-gray-100 relative overflow-y-auto pt-2 px-4">
              <div className="p-10 flex flex-col h-full">
                <div className="mb-7 flex items-center flex-grow-0">
                  <h1 className="mr-8 text-4xl">Cupones de Descuento</h1>
                  <Link
                    to="/coupons/new"
                    className="p-2 text-sm hover:bg-gray-100 underline uppercase text-blue-400 hover:no-underline"
                  >
                    Crear Nuevo
                  </Link>
                </div>
                <MiniSearchBar placeholder="Buscar cupón" onRequestSearch={fetchDataWithFilters} />
                <div className="w-full bg-white flex-grow">
                  <CouponDataGrid
                    data={coupons}
                    loading={loading}
                    country={country}
                    setCountry={(value: Country) => setCountry(value)}
                    toggleCoupon={handleToggleCoupon}
                    deleteCoupon={handleDeleteCoupon}
                  />
                </div>
              </div>
            </div>
          </>
        </PrivateRoute>
      </TokenValidator>
    </Layout>
  );
};

export default Coupons;
